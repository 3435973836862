import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter
    scrollBehavior={() => {
      console.log('Scrolling to top.');
      return { x: 0, y: 0 };
    }}
  >
    <App />
  </BrowserRouter>
);


