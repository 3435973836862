import React from 'react'
import JuniourRooms from './RoomTypes/JuniourRooms';
import ExecutiveRooms from './RoomTypes/ExecutiveRooms';
import SuperDeluxRooms from './RoomTypes/SuperDeluxRooms'
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    // Carousel Start
    <div>
      <div className="container-fluid p-0 mb-5">
        <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src="/new-img/balcony.jpg" alt="Carousel Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: 700 }}>
                  <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">Luxury Living</h6>
                  <h1 className="display-3 text-white mb-4 animated slideInDown">Discover A Brand Luxurious Hotel</h1>
                  <Link to="/room"  className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Our Rooms</Link>
                  <a href="https://wa.me/9719854060?text=I'm%20inquiring%20about%20the%20executive%20suit" target="_blank" className="btn btn-light py-md-3 px-md-5 animated slideInRight">Book A Room</a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src="/new-img/front.jpg" alt="Carousel Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: 700 }}>
                  <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">Luxury Living</h6>
                  <h1 className="display-3 text-white mb-4 animated slideInDown">Discover A Brand Luxurious Hotel</h1>
                  <a href="./room.html" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Our Rooms</a>
                  <a href="https://wa.me/9719854060?text=I'm%20inquiring%20about%20the%20executive%20suit" target="_blank" className="btn btn-light py-md-3 px-md-5 animated slideInRight">Book A Room</a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src="/new-img/double-bed.jpg" alt="Carousel Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: 700 }}>
                  <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">Luxury Living</h6>
                  <h1 className="display-3 text-white mb-4 animated slideInDown">Discover A Brand Luxurious Hotel</h1>
                  <Link to="/room"  className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Our Rooms</Link>
                  <a href="https://wa.me/9719854060?text=I'm%20inquiring%20about%20the%20executive%20suit" target="_blank" className="btn btn-light py-md-3 px-md-5 animated slideInRight">Book A Room</a>
                </div>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* Carousel End */}
      {/* Booking Start */}
      {/* <div className="container-fluid booking pb-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container">
          <div className="bg-white shadow" style={{ padding: 35 }}>
            <div className="row g-2">
              <div className="col-md-10">
                <div className="row g-2">
                  <div className="col-md-3">
                    <div className="date" id="date1" data-target-input="nearest">
                      <input type="text" className="form-control datetimepicker-input" placeholder="Check in" data-target="#date1" data-toggle="datetimepicker" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="date" id="date2" data-target-input="nearest">
                      <input type="text" className="form-control datetimepicker-input" placeholder="Check out" data-target="#date2" data-toggle="datetimepicker" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <select className="form-select">
                      <option selected>Adult</option>
                      <option value={1}>Adult 1</option>
                      <option value={2}>Adult 2</option>
                      <option value={3}>Adult 3</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <select className="form-select">
                      <option selected>Child</option>
                      <option value={1}>Child 1</option>
                      <option value={2}>Child 2</option>
                      <option value={3}>Child 3</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <button className="btn btn-primary w-100">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Booking End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary text-uppercase">About Us</h6>
              <h2 className="mb-4">Welcome to <span className="text-primary text-uppercase">Hotel Najma Arshi</span></h2>
              <p className="mb-4">Experience tranquility and affordability at HOTEL NAJMA ARSHI in Nainital. Nestled amidst the serene hills, our hotel offers comfortable stays, breathtaking views, and warm hospitality. Whether you're exploring the town or seeking relaxation, we provide the perfect retreat for an unforgettable hill station getaway.</p>
              <div className="row g-3 pb-4">
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-hotel fa-2x text-primary mb-2" />
                      <h2 className="mb-1" data-toggle="counter-up">14</h2>
                      <p className="mb-0">Rooms</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-users-cog fa-2x text-primary mb-2" />
                      <h2 className="mb-1" data-toggle="counter-up">6 - 8</h2>
                      <p className="mb-0">Staffs</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-users fa-2x text-primary mb-2" />
                      <h2 className="mb-1" data-toggle="counter-up">5000+</h2>
                      <p className="mb-0">Clients</p>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/room" className="btn btn-primary py-3 px-5 mt-2" href>Explore More</Link>
            </div>
            <div className="col-lg-6">
              <div className="row g-3">
                <div className="col-6 text-end">
                  <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.1s" src="/new-img/front.jpg" style={{ marginTop: '25%' }} />
                </div>
                <div className="col-6 text-start">
                  <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.3s" src="img/about-4.jpg" />
                </div>
                <div className="col-6 text-end">
                  <img className="img-fluid rounded w-50 wow zoomIn" data-wow-delay="0.5s" src="img/about-3.jpeg" />
                </div>
                <div className="col-6 text-start">
                  <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.7s" src="img/about-2.jpeg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Room Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary text-uppercase">Our Rooms</h6>
            <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Rooms</span></h1>
          </div>
          <div className="row g-4">
            <JuniourRooms image="/new-img/delux2.jpg" />
            <ExecutiveRooms image="/img/room-2.jpeg"/>
            <SuperDeluxRooms image="/img/room-1.jpeg"/>
          </div>
        </div>
      </div>
      {/* Room End */}
      {/* Service Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary text-uppercase">Our Services</h6>
            <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Services</span></h1>
          </div>
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <a className="service-item rounded" href>
                <div className="service-icon bg-transparent border rounded p-1">
                  <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                    <i className="fa fa-hotel fa-2x text-primary" />
                  </div>
                </div>
                <h5 className="mb-3">        Open Terrace.
                </h5>
                <p className="text-body mb-0">The open terrace offers a breathtaking view of the city skyline and is the perfect place to relax and unwind.</p>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
              <a className="service-item rounded" href>
                <div className="service-icon bg-transparent border rounded p-1">
                  <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                    <i className="fa fa-utensils fa-2x text-primary" />
                  </div>
                </div>
                <h5 className="mb-3">        Kitchen Facilities
                </h5>
                <p className="text-body mb-0">Indulge in our well-equipped kitchen facilities and whip up delicious, meals that tantalize your taste buds.</p>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <a className="service-item rounded" href>
                <div className="service-icon bg-transparent border rounded p-1">
                  <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                    <i className="fa fa-spa fa-2x text-primary" />
                  </div>
                </div>
                <h5 className="mb-3">  24 hours hot water</h5>
                <p className="text-body mb-0">Enjoy round-the-clock access to soothing, hot water</p>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
              <a className="service-item rounded" href>
                <div className="service-icon bg-transparent border rounded p-1">
                  <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                    <i className="fa fa-swimmer fa-2x text-primary" />
                  </div>
                </div>
                <h5 className="mb-3">        Indian Food and Chinese Food Available.
                </h5>
                <p className="text-body mb-0">Savor the best of both worlds with a delectable selection of Indian and Chinese cuisine on our menu.</p>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <a className="service-item rounded" href>
                <div className="service-icon bg-transparent border rounded p-1">
                  <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                    <i className="fa fa-glass-cheers fa-2x text-primary" />
                  </div>
                </div>
                <h5 className="mb-3">                Pickup and Drop Service.
                </h5>
                <p className="text-body mb-0">Convenient pickup and drop-off services available for your ease and comfort.</p>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
              <a className="service-item rounded" href>
                <div className="service-icon bg-transparent border rounded p-1">
                  <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                    <i className="fa fa-dumbbell fa-2x text-primary" />
                  </div>
                </div>
                <h5 className="mb-3">        Bike and Car Rental Available.
                </h5>
                <p className="text-body mb-0">Explore at your own pace with bike and car rental options at your fingertips</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
      {/* Testimonial Start */}
      <div className="container-xxl testimonial my-5 py-5 bg-dark wow zoomIn" data-wow-delay="0.1s">
        <div className="container">
          <div className="owl-carousel testimonial-carousel py-5 \">
            <div className="testimonial-item position-relative bg-white rounded overflow-hidden rounded-pill mt-4">
              <p>Very nice and peaceful place ☺️👍
                Rooms are very neat and clean
                Staff members is very humble and food is also good 😋
                And the best part is ropeway and boating is near the hotel.</p>
              <div className="d-flex align-items-center">
                <img className="img-fluid flex-shrink-0 rounded" src="img/testimonial-1.png" style={{ width: 45, height: 45 }} />
                <div className="ps-3">
                  <h6>Fiza Ali </h6>
                </div>
              </div>
              {/* <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i> */}
            </div>
            <div className="testimonial-item position-relative bg-white rounded overflow-hidden rounded-pill mt-4">
              <p>Very impressive and supportive
                Food was also good and 😋😋😋</p>
              <div className="d-flex align-items-center">
                <img className="img-fluid flex-shrink-0 rounded" src="img/testimonial-2.png" style={{ width: 45, height: 45 }} />
                <div className="ps-3">
                  <h6 className="fw-bold mb-1">Harish Bisht Kanara</h6>
                </div>
              </div>
              {/* <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i> */}
            </div>
            <div className="testimonial-item position-relative bg-white rounded overflow-hidden rounded-pill mt-4">
              <p>Good Behavior. Delicious food. Good facilities.
              </p>
              <div className="d-flex align-items-center">
                <img className="img-fluid flex-shrink-0 rounded" src="img/testimonial-3.png" style={{ width: 45, height: 45 }} />
                <div className="ps-3">
                  <h6 className="fw-bold mb-1">Mohd Imran</h6>
                </div>
              </div>
              {/* <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i> */}
            </div>
          </div>
        </div>
      </div>
      {/* Testimonial End */}

    </div>


  )
}

export default Home