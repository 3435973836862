import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div>
      <div
        className="container-fluid bg-dark text-light footer wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container pb-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-4">
              <div className="bg-primary rounded p-4">
                <Link to="/">
                  <h3 className="text-white text-uppercase mb-3">
                    Hotel Najma Arshi
                  </h3>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <h6 className="section-title text-start text-primary text-uppercase mb-4">
                Contact
              </h6>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3" />
                Hotel Najma Arshi, Mallital, Nainital
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                05942-235796
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                +91 9719854060
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3" />
                mohammadadil2193@gmail.com
              </p>
              <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" href>
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-outline-light btn-social" href>
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-outline-light btn-social" href>
                  <i className="fab fa-youtube" />
                </a>
                <a className="btn btn-outline-light btn-social" href>
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="row gy-5 g-4">
                <div className="col-md-6">
                  <h6 className="section-title text-start text-primary text-uppercase mb-4">
                    Company
                  </h6>
                  <Link to="/about" className="btn btn-link" href>
                    About Us
                  </Link>
                  <Link to="/contact" className="btn btn-link" href>
                    Contact Us
                  </Link>
                  <Link to="/contact" className="btn btn-link" href>
                    Privacy Policy
                  </Link>
                  <Link to="contact" className="btn btn-link" href>
                    Terms &amp; Condition
                  </Link>
                  <Link to="" className="btn btn-link" href>
                    Support
                  </Link>
                </div>
                <div className="col-md-6">
                  <h6 className="section-title text-start text-primary text-uppercase mb-4">
                    Nearby
                  </h6>
                  <Link to="/nearby" className="btn btn-link" href>
                    Parking (Walking Distance)
                  </Link>
                  <Link to="/nearby" className="btn btn-link" href>
                    Restaurants (Walking Distance)
                  </Link>
                  <Link to="/nearby" className="btn btn-link" href>
                    Nainital Mall Road (Walking Distance)
                  </Link>
                  <Link to="/nearby" className="btn btn-link" href>
                    Naini Lake (Walking Distance)
                  </Link>
                  <Link to="/nearby" className="btn btn-link" href>
                    Nainital RopeWay (Walking Distance)
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                ©{" "}
                <a className="border-bottom" href="#">
                  Hotel Najma Arshi
                </a>
                , All Right Reserved @2022 Developed By{" "}
                <a
                  className="border-bottom"
                  href="https://siddharthbisht.vercel.app"
                >
                  Siddharth Bisht
                </a>
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <Link to="/">Home</Link>
                  <Link to="/contact">Help</Link>
                  <Link to="/contact">FQAs</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
