import React from 'react'
import { Link } from 'react-router-dom'

const ExecutiveRooms = ({image}) => {
    return (
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                <Link to="/viewroom/executive">
                    <img className="img-fluid" src={image} alt /></Link>
                    <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">Najma Arshi</small>
                </div>
                <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                        <h5 className="mb-0">Executive Suite</h5>
                        <div className="ps-2">
                            <small className="fa fa-star text-primary" />
                            <small className="fa fa-star text-primary" />
                            <small className="fa fa-star text-primary" />
                            <small className="fa fa-star text-primary" />
                            <small className="fa fa-star text-primary" />
                        </div>
                    </div>
                    <div className="d-flex mb-3">
                        <small className="border-end me-3 pe-3"><i className="fa fa-bed text-primary me-2" />2 Bed</small>
                        <small className="border-end me-3 pe-3"><i className="fa fa-bath text-primary me-2" />1 Bath</small>
                        <small><i className="fa fa-wifi text-primary me-2" />Wifi</small>
                    </div>
                    <p className="text-body mb-3">
                        The executive suite features a combination of one double bedroom and one single bedroom, equipped with all the necessary amenities, including a television and both Indian and Western-style bathrooms, ensuring a comfortable stay.
                    </p>
                    <div className="d-flex justify-content-between">
                        <Link to="/viewroom/executive" className="btn btn-sm btn-primary rounded py-2 px-4" href>View Detail</Link>
                        <a className="btn btn-sm btn-dark rounded py-2 px-4" href="https://wa.me/9719854060?text=I'm%20inquiring%20about%20the%20executive%20suit" target="_blank">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExecutiveRooms