
import './App.css';
import './Styles/bootstrap.min.css'
import Routes from './Routes'
function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
