
import React from 'react'
import { Link } from 'react-router-dom';

const Error500 = () => {
  return (
    <div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h2>Let me try again!</h2>
				<h2>500 - Sorry, it's me, not you.</h2>
			</div>
      <Link to="/">Go TO Homepage</Link>

		</div>
	</div>
  )
}

export default Error500;