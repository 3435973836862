import React from 'react'
import { Link } from 'react-router-dom'
function Header() {
    return (
        <div className="container-fluid bg-dark px-0">
            <div className="row gx-0">
                <div className="col-lg-3 bg-dark d-none d-lg-block">
                    <Link to="/" className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
                        <h3 className="m-0 text-primary text-uppercase">Hotel Najma Arshi</h3>
                    </Link>
                </div>
                <div className="col-lg-9">
                    <div className="row gx-0 bg-white d-none d-lg-flex">
                        <div className="col-lg-7 px-5 text-start">
                            <div className="h-100 d-inline-flex align-items-center py-2 me-4">
                                <i className="fa fa-envelope text-primary me-2" />
                                <p className="mb-0">mohammadadil2193@gmail.com</p>
                            </div>
                            <div className="h-100 d-inline-flex align-items-center py-2">
                                <i className="fa fa-phone-alt text-primary me-2" />
                                <p className="mb-0">05942-235796 &nbsp; &nbsp; &nbsp;</p>
                            </div>

                            <div className="h-100 d-inline-flex align-items-center py-2">
                                <i className="fa fa-phone-alt text-primary me-2" />
                                <p className="mb-0">97198 54060</p>
                            </div>
                        </div>
                        <div className="col-lg-5 px-5 text-end">
                            <div className="d-inline-flex align-items-center py-2">
                                <Link className="me-3" to><i className="fab fa-facebook-f" /></Link>
                                <Link className="me-3" to><i className="fab fa-twitter" /></Link>
                                <Link className="me-3" to><i className="fab fa-linkedin-in" /></Link>
                                <Link className="me-3" to><i className="fab fa-instagram" /></Link>
                                <Link className to><i className="fab fa-youtube" /></Link>
                            </div>
                        </div>
                    </div>
                    <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
                        <Link to="/" className="navbar-brand d-block d-lg-none">
                            <h1 className="m-0 text-primary text-uppercase">Hotel Najma Arshi</h1>
                        </Link>
                        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse justify-content-center" id="navbarCollapse">
                            <div className="navbar-nav mr-auto py-0">
                                <Link to="/" className="nav-item nav-link active">Home</Link>
                                <Link to="about" className="nav-item nav-link">About</Link>
                                <Link to="services" className="nav-item nav-link">Services</Link>
                                <Link to="room" className="nav-item nav-link">Rooms</Link>
                                <Link to="nearby" className="nav-item nav-link">NearBy Location</Link>
                                <Link to="contact" className="nav-item nav-link">Contact</Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>


    )
}

export default Header