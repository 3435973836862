import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from 'react-router-dom'
import ScrollToTopOnNavigation from "./ScrollToTopOnNavigation";
function MainLayout() {
    return (
        <>
        <ScrollToTopOnNavigation />
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}
export default MainLayout