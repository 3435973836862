import React from 'react'

const Nearby = () => {
  return (
    <div>
      <div className="container-fluid page-header mb-5 p-0" style={{ backgroundImage: 'url(img/carousel-1.jpg)' }}>
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">NearBy Locations</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item text-white active" aria-current="page">NearBy Locations</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div>
        {/* <div className="container-fluid booking pb-5 wow fadeIn" data-wow-delay="0.1s">
          <div className="container">
            <div className="bg-white shadow" style={{ padding: 35 }}>
              <div className="row g-2">
                <div className="col-md-10">
                  <div className="row g-2">
                    <div className="col-md-3">
                      <div className="date" id="date1" data-target-input="nearest">
                        <input type="text" className="form-control datetimepicker-input" placeholder="Check in" data-target="#date1" data-toggle="datetimepicker" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="date" id="date2" data-target-input="nearest">
                        <input type="text" className="form-control datetimepicker-input" placeholder="Check out" data-target="#date2" data-toggle="datetimepicker" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <select className="form-select">
                        <option selected>Adult</option>
                        <option value={1}>Adult 1</option>
                        <option value={2}>Adult 2</option>
                        <option value={3}>Adult 3</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <select className="form-select">
                        <option selected>Child</option>
                        <option value={1}>Child 1</option>
                        <option value={2}>Child 2</option>
                        <option value={3}>Child 3</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <button className="btn btn-primary w-100">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="section-title text-center text-primary text-uppercase">NearBy Places</h6>
              <h1 className="mb-5">Must Visit <span className="text-primary text-uppercase">NearBy Places</span></h1>
            </div>
            <div className="row g-4">
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="room-item shadow rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src="img/n1.jpg" alt />
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">Nainital RopeWay </h5>
                    </div>
                    <p className="text-body mb-3">(Walking Distance 2min/50 mtr)</p>
                    <div className="d-flex justify-content-center">
                      <a className="btn btn-sm btn-primary rounded py-2 px-4" href="https://goo.gl/maps/SDM57farqSr1NGkWA" target='_blank'>Directions</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="room-item shadow rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src="img/n7.jpg" alt />
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">Restaurants </h5>
                    </div>
                    <p className="text-body mb-3">(Walking Distance 5min/100 mtr)</p>
                    <div className="d-flex justify-content-center">
                      <a className="btn btn-sm btn-primary rounded py-2 px-4" href="https://www.google.com/maps/search/nanital+restaurants/@29.3926082,79.4525382,17z/data=!4m2!2m1!6e5?entry=ttu" target='_blank'>Directions</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="room-item shadow rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src="img/n3.jpg" alt />
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">Naini Lake </h5>
                    </div>
                    <p className="text-body mb-3">(Walking Distance 2min/50 mtr)</p>
                    <div className="d-flex justify-content-center">
                      <a className="btn btn-sm btn-primary rounded py-2 px-4" href="https://goo.gl/maps/S3ym4hA5SG7J1zpr5" target='_blank'>Directions</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="room-item shadow rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src="img/n4.jpg" alt />
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">Nainital Mall Road </h5>
                    </div>
                    <p className="text-body mb-3">(Walking Distance 2min/50 mtr)</p>
                    <div className="d-flex justify-content-center">
                      <a className="btn btn-sm btn-primary rounded py-2 px-4" href="https://goo.gl/maps/Fr4KsDnQJNzcZnuz7" target='_blank'>Directions</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="room-item shadow rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src="img/n5.jpg" alt />
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">Nanda Devi Temple</h5>
                    </div>
                    <p className="text-body mb-3">(Walking Distance 5min/100 mtr)</p>
                    <div className="d-flex justify-content-center">
                      <a className="btn btn-sm btn-primary rounded py-2 px-4" href="https://goo.gl/maps/jURwcDzqC6gKs2bJ7" target='_blank'>Directions</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="room-item shadow rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src="img/n6.jpg" alt />
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">Nainital Gurudwara </h5>
                    </div>
                    <p className="text-body mb-3">(Walking Distance 5min/100 mtr)</p>
                    <div className="d-flex justify-content-center">
                      <a className="btn btn-sm btn-primary rounded py-2 px-4" href="https://goo.gl/maps/Sw9Cti39asAEoGvU8" target='_blank'>Directions</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Nearby