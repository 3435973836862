import React, {useState} from "react";
import { useParams } from "react-router-dom";
import JuniourRooms from "./JuniourRooms";
import SuperDeluxRooms from "./SuperDeluxRooms";
import ExecutiveRooms from "./ExecutiveRooms";
import InfoBox from "../HelperComponents/InfoBox";
import FullScreenImage from "../HelperComponents/FullScreeenImage"

const juniorMedia = [
  "/new-img/double-bed-side.jpg",
  "/new-img/double-wide.jpg",
  "/img/room-3.jpeg",
]
const executiveMedia = [
        "/img/room-1.jpeg",
        "/new-img/double-single-bed.jpg",
        "/img/room-2.jpeg",
        "/new-img/washroom-nowide.jpg",
]
const deluxMedia = [
        "/new-img/2-double-bed.jpg",
        "/new-img/delux1.jpeg",
        "/new-img/superluxury2.jpg",
]

const ViewRoomDetail = () => {
  const { roomtype } = useParams();
  let room = {};
  const [file,setFile] = useState(null);
  if (roomtype === "juniour") {
    room = {
      name: "Double Bed Room / Juniour Room",
      maxGuest: "2 Adult 1 Child",
      maxAdult: 2,
      maxChild: 1,
      bedType: 2,
      bedTypeText: "Double",
      media: juniorMedia,
    }

  }
  else if (roomtype === "executive") {
    room = {
      name: "3 Double Bed Room /Executive Suite",
      maxGuest: "3 Adult 1 Child",
      maxAdult: 3,
      maxChild: 1,
      bedType: 3,
      bedTypeText: "Triple",
      media: executiveMedia
    }

  }
  else if (roomtype === "delux") {
    room = {
      name: "4 Bed Room /Super Delux Suite",
      maxGuest: "4 Adult 2 Child",
      maxAdult: 4,
      maxChild: 2,
      bedType: 4,
      bedTypeText: "Four",
      media: executiveMedia
    }

  }
  return (
    <div>
      <div className="container-fluid p-5 mb-5 mt-5 ">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h1 className="mb-5">
            {" "}
            <span className="text-primary text-uppercase">
              {" "}
              {room.name}
            </span>
          </h1>
        </div>
        <div
          id="header-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100 rounded-3" src={`${room.media[0]}`} alt="Room Photograph"
              onClick={() => {
              setFile(`${room.media[0]}`);
            }}
              />
            </div>
            <div className="carousel-item">
              <img className="w-100 rounded-3" src={`${room.media[1]}`} alt="Room Photograph"
              onClick={() => {
              setFile(`${room.media[1]}`);
            }}
              />
            </div>
            <div className="carousel-item">
              <img className="w-100 rounded-3" src={`${room.media[2]}`} alt="Room Photograph"
              onClick={() => {
              setFile(`${room.media[2]}`);
            }}
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>


      <div className="row g-4">
        <InfoBox headerInfo={`${room.bedType} Bed Room`} paraInfo={`This rooms offers you with ${room.bedTypeText} Bed Room `} icon="fa fa-hotel fa-2x text-primary" />
        <InfoBox headerInfo="Attached Washroom" paraInfo="This rooms offers you with Attached washroom English or Hindi up to your choice" icon="fa fa-hotel fa-2x text-primary" />
        <InfoBox headerInfo={`${room.maxAdult} Adults ${room.maxChild} Child`} paraInfo={`This room can accomodate with ${room.maxAdult + room.maxChild} Maximum Guests, ${room.maxAdult} Adults and ${room.maxChild} Child`} icon="fa fa-hotel fa-2x text-primary" />
      </div>


      <div className="text-center wow fadeInUp mb-6" data-wow-delay="0.1s">
        {/* <h1 className="mb-1">Stars </h1>

      <div className="ps-2">
        <small className="fa fa-star text-primary fs-1" />
        <small className="fa fa-star text-primary fs-1" />
        <small className="fa fa-star text-primary fs-1" />
        <small className="fa fa-star text-primary fs-1" />
        <small className="fa fa-star text-primary fs-1" />
      </div> */}
        <div>
          <div className="mt-5">
            <h4 className="section-title text-center text-primary text-uppercase mb-4">Things To Remember</h4>
          </div>
          <div className="row g-3 pb-4">
            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
              <div className="border rounded p-1">

                <h4 className="mb-1" data-toggle="counter-up">Check In -</h4>
                <p className="mb-0">Anytime</p>
              </div>
            </div>
            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
              <div className="border rounded p-1">

                <h4 className="mb-1" data-toggle="counter-up">Check Out -</h4>
                <p className="mb-0">10 AM</p>
              </div>
            </div> <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
              <div className="border rounded p-1">

                <h4 className="mb-1" data-toggle="counter-up">Maximum Guests In Room</h4>
                <p className="mb-0">{room.maxAdult} Adults {room.maxChild} Child</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-center wow fadeInUp mb-5" data-wow-delay="0.1s">
          <h4 className="section-title text-center text-primary text-uppercase">Other Rooms</h4>
        </div>
        <div className="row g-3">
          <JuniourRooms />
          <SuperDeluxRooms />
          <ExecutiveRooms />
        </div>
      </div>
      <div className="popup-media" style={{ display: file ? "block" : "none" }}>
        <span onClick={() => setFile(null)}>&times;</span>
          <img src={file} />
      </div>
    </div>
  )
};
export default ViewRoomDetail;
