import React from 'react'
const Services = () => {
    return (
        <div>
            {/* Page Header Start */}
            <div className="container-fluid page-header mb-5 p-0" style={{ backgroundImage: 'url(img/carousel-1.jpg)' }}>
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center pb-5">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">Services</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center text-uppercase">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Services</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            {/* Page Header End */}
            {/* Booking Start */}
            {/* <div className="container-fluid booking pb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container">
                    <div className="bg-white shadow" style={{ padding: 35 }}>
                        <div className="row g-2">
                            <div className="col-md-10">
                                <div className="row g-2">
                                    <div className="col-md-3">
                                        <div className="date" id="date1" data-target-input="nearest">
                                            <input type="text" className="form-control datetimepicker-input" placeholder="Check in" data-target="#date1" data-toggle="datetimepicker" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="date" id="date2" data-target-input="nearest">
                                            <input type="text" className="form-control datetimepicker-input" placeholder="Check out" data-target="#date2" data-toggle="datetimepicker" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <select className="form-select">
                                            <option selected>Adult</option>
                                            <option value={1}>Adult 1</option>
                                            <option value={2}>Adult 2</option>
                                            <option value={3}>Adult 3</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <select className="form-select">
                                            <option selected>Child</option>
                                            <option value={1}>Child 1</option>
                                            <option value={2}>Child 2</option>
                                            <option value={3}>Child 3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-primary w-100">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Booking End */}
            {/* Service Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title text-center text-primary text-uppercase">Our Services</h6>
                        <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Services</span></h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <a className="service-item rounded" href>
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-hotel fa-2x text-primary" />
                                    </div>
                                </div>
                                <h5 className="mb-3">        Open Terrace.
                                </h5>
                                <p className="text-body mb-0">The open terrace offers a breathtaking view of the city skyline and is the perfect place to relax and unwind.</p>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                            <a className="service-item rounded" href>
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-utensils fa-2x text-primary" />
                                    </div>
                                </div>
                                <h5 className="mb-3">        Kitchen Facilities
                                </h5>
                                <p className="text-body mb-0">Indulge in our well-equipped kitchen facilities and whip up delicious, meals that tantalize your taste buds.</p>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <a className="service-item rounded" href>
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-spa fa-2x text-primary" />
                                    </div>
                                </div>
                                <h5 className="mb-3">  24 hours hot water</h5>
                                <p className="text-body mb-0">Enjoy round-the-clock access to soothing, hot water</p>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                            <a className="service-item rounded" href>
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-swimmer fa-2x text-primary" />
                                    </div>
                                </div>
                                <h5 className="mb-3">        Indian Food and Chinese Food Available.
                                </h5>
                                <p className="text-body mb-0">Savor the best of both worlds with a delectable selection of Indian and Chinese cuisine on our menu.</p>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <a className="service-item rounded" href>
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-glass-cheers fa-2x text-primary" />
                                    </div>
                                </div>
                                <h5 className="mb-3">                Pickup and Drop Service.
                                </h5>
                                <p className="text-body mb-0">Convenient pickup and drop-off services available for your ease and comfort.</p>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <a className="service-item rounded" href>
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-dumbbell fa-2x text-primary" />
                                    </div>
                                </div>
                                <h5 className="mb-3">        Bike and Car Rental Available.
                                </h5>
                                <p className="text-body mb-0">Explore at your own pace with bike and car rental options at your fingertips</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Service End */}
        </div>

    )
}

export default Services