import React from 'react'

const InfoBox = ({ headerInfo = "Header Info", paraInfo = "para information", icon = "fa fa-hotel fa-2x text-primary" }) => {
    return (
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <a className="service-item rounded" href>
                <div className="service-icon bg-transparent border rounded p-1">
                    <div className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                        <i className={icon} />
                    </div>
                </div>
                <h5 className="mb-3">{headerInfo}</h5>
                <p className="text-body mb-0">{paraInfo}</p>
            </a>
        </div>
    )
}

export default InfoBox