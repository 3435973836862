import React from "react";
import { Link } from "react-router-dom";
import JuniourRooms from "./RoomTypes/JuniourRooms";
import ExecutiveRooms from "./RoomTypes/ExecutiveRooms";
import SuperDeluxRooms from "./RoomTypes/SuperDeluxRooms";

const Room = () => {
  return (
    <div>
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Rooms
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Pages</a>
                </li>
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Rooms
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid booking pb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container">
                    <div className="bg-white shadow" style={{ padding: 35 }}>
                        <div className="row g-2">
                            <div className="col-md-10">
                                <div className="row g-2">
                                    <div className="col-md-3">
                                        <div className="date" id="date1" data-target-input="nearest">
                                            <input type="text" className="form-control datetimepicker-input" placeholder="Check in" data-target="#date1" data-toggle="datetimepicker" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="date" id="date2" data-target-input="nearest">
                                            <input type="text" className="form-control datetimepicker-input" placeholder="Check out" data-target="#date2" data-toggle="datetimepicker" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <select className="form-select">
                                            <option selected>Adult</option>
                                            <option value={1}>Adult 1</option>
                                            <option value={2}>Adult 2</option>
                                            <option value={3}>Adult 3</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <select className="form-select">
                                            <option selected>Child</option>
                                            <option value={1}>Child 1</option>
                                            <option value={2}>Child 2</option>
                                            <option value={3}>Child 3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-primary w-100">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary text-uppercase">
              Our Rooms
            </h6>
            <h1 className="mb-5">
              Explore Our{" "}
              <span className="text-primary text-uppercase">Rooms</span>
            </h1>
          </div>
          <div className="row g-4">
            <SuperDeluxRooms image="/new-img/superluxury2.jpg" />
            <JuniourRooms image="/new-img/double-bed-side.jpg"  />
            <ExecutiveRooms image="/img/room-1.jpeg" />
            <JuniourRooms image="/new-img/delux2.jpg" />
            <JuniourRooms image="/img/room-3.jpeg" />
            <ExecutiveRooms image="/img/room-2.jpeg" />
            <JuniourRooms image="/new-img/delux2.jpg" />
            <JuniourRooms image="/new-img/double-bed.jpg" />
            <ExecutiveRooms image="/new-img/double-wide.jpg" />
            <JuniourRooms image="/new-img/double-bed-side.jpg" />
            <JuniourRooms image="/new-img/bed-back.jpg" />
            <JuniourRooms image="/new-img/bed-front.jpg" />
            <ExecutiveRooms image="/new-img/double-single-bed.jpg" />
            <ExecutiveRooms image="/new-img/double-bed-side.jpg" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Room;
