import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from '../layout/MainLayout';

// we will import pages which are required in the site
import About from '../Components/About';
import Contact from '../Components/Contact';
import Home from '../Components/Home';
import Nearby from '../Components/Nearby';
import Room from '../Components/Room';
import Services from '../Components/Services';
import ViewRoomsDetail from "../Components/RoomTypes/ViewRoomsDetail";

// Error Pages
import Error404 from '../Components/ErrorPages/Error404'
import Error500 from '../Components/ErrorPages/Error500'


function Router() {
    return useRoutes([
        {
            path: "",
            element: <MainLayout />,
            children: [
                {
                    path: "",
                    element: <Home />,
                },
                {
                    path: "/about",
                    element: <About />
                },
                {
                    path: "/contact",
                    element: <Contact />,
                },
                {
                    path: "/nearby",
                    element: <Nearby />
                },
                {
                    path: "/room",
                    element: <Room />,
                },
                {
                    path: "/viewroom/:roomtype",
                    element: <ViewRoomsDetail />
                },
                {
                    path: "/services",
                    element: <Services />
                }
            ]
        },
        { path: "404", element: <Error404 /> },
        { path: "500", element: <Error500 /> },
        { path: "*", element: <Navigate to="/404" replace /> }
    ])
}

export default Router;